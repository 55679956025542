<!-- VmrToastContainer -->
@defer (when renderToastContainer(); prefetch on idle) {
  @if (renderToastContainer()) {
    <vmr-toast-container
      [position]="position()"
      [expandByDefault]="expand()"
      [showCloseButton]="closeButton()"
    />
  }
}

<!-- Template overrides -->
<ng-template #leftContent>
  <a href="/" class="docs-logo">
    <h3>
      <vmr-logo />
      <vmr-logo-vertical />
    </h3>
  </a>
</ng-template>

<ng-template #footerContent>
  <div class="docs-footer">
    Copyright &copy; {{_year}} Vermeer Corporation.<br />All rights reserved.
  </div>
</ng-template>

<ng-template #rightContent>
  <div class="docs-header-controls">
    <vmr-theme-toggle />

    <a
      target="_blank"
      href="{{_repoUrl}}"
      ngDocTooltip="Repository on GitHub"
    ><app-github-icon /></a>

    <a
      target="_blank"
      href="{{_issuesUrl}}"
      ngDocTooltip="Make a suggestion"
    ><app-lightbulb-icon /></a>
  </div>
</ng-template>

<!-- Layout -->
<ng-doc-root [footerContent]="footerContent">
  <ng-doc-navbar class="ng-doc-navbar" [leftContent]="leftContent" [rightContent]="rightContent" />
  <ng-doc-sidebar class="ng-doc-sidebar" />
  <router-outlet />
</ng-doc-root>
